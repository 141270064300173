import React from "react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./candidates.css"

export default class Modal extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
          speaker: {}
        };
        this.changeFilter = this.changeFilter.bind( this );
    }

    promiseOptions = ( inputValue => {
        let needle = null;
        if( inputValue ) {
            needle = inputValue.toLowerCase();
        }
        let gender;
        if( this.props.config.include.men && !this.props.config.include.women ) {
            gender = "male";
        } else if( !this.props.config.include.men && this.props.config.include.women ) {
            gender = "female";
        }

        let agegroup;
        if( this.props.config.include.adults && !this.props.config.include.youth ) {
            agegroup = "adults";
        } else if( !this.props.config.include.adults && this.props.config.include.youth ) {
            agegroup = "youth";
        }
        const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
            queryParams: {}
        }

        if( gender || agegroup ) {

            if( gender ) {
                options.queryParams.gender = gender;
            }
            if( agegroup ) {
                options.queryParams.agegroup = agegroup;
            }
        }

        options.queryParams.relativedate = this.props.config.speaker.date;

        return this.props.fetchWithQuery( "/api/candidates", options )
        .then( ( response ) => {
            return response.json();
        } )
        .then( ( jsonResponse ) => {
            const list = [];
            if( this.props.config.speaker && this.props.config.speaker.name ) {
                list.push(
                    {
                        label: `remove --${this.props.config.speaker.name} --`,
                        value: ""
                    }
                );
            }
            let list2 = jsonResponse.filter( ( candidate ) => {
                if( needle ) {
                    let haystack = candidate.name.toLowerCase();
                    return ( haystack.indexOf( needle ) !== -1 );
                } else {
                    return true;
                }
            }).map( ( candidate ) => {
                if( candidate.months ) {
                    return {
                        label: candidate.name + " " + candidate.months,
                        value: candidate.uid
                    }
                } else {
                    return {
                        label: candidate.name,
                        value: candidate.uid
                    }
                }
            } );

            return list.concat( list2 );
        })
        .catch( ( err ) => {
            console.log( err );
        } );
    });

    createMethod( name ) {
        const toCreate = {
            label: name,
            value: "GUEST"
        }
        this.props.selectCandidate( toCreate );
    }

    changeMethod( selected ) {
        this.props.selectCandidate( selected );
    }

    changeFilter( e ) {
        this.props.hideSelect();
        this.props.includeCandidates( e );
        setTimeout( this.props.showSelect, 5 );
    }

    render( ) {
        // text field
        // buttons
        // list of speakers and their since
        let showHideProp;
        let selectTag;
        if( this.props.config.show ) {
            showHideProp = "modal display-block";
            if( this.props.config.showSelect ) {
                selectTag = <AsyncCreatableSelect onChange={this.changeMethod.bind(this)} onCreateOption={this.createMethod.bind(this)} defaultMenuIsOpen blurInputOnSelect closeMenuOnSelect={true} autoFocus defaultOptions={true} loadOptions={ this.promiseOptions } />
            } else {
                selectTag = "";
            }
        } else {
            showHideProp = "modal";
            selectTag = "";
        }


        const speaker = this.props.config.speaker;

        let slotName;
        switch( speaker.slot ) {
            case "1":
                slotName = "1st speaker";
                break;
            case "2":
                slotName = "2nd speaker";
                break;
            case "3":
                slotName = "3rd speaker";
                break;
            default:
                slotName = "";
        }

        let menClass = this.props.config.include.men ? "pressed": "not_pressed";
        let womenClass = this.props.config.include.women ? "pressed": "not_pressed";
        let youthClass = this.props.config.include.youth ? "pressed": "not_pressed";
        let adultClass = this.props.config.include.adults ? "pressed": "not_pressed";

        return (
            <div className={showHideProp} id="modal">
                <button id="closebtn" onClick={this.props.close}>X</button>
                <div id="modal-main">

                    <h4>{slotName}</h4>
                    <h4>Sunday {this.props.config.speaker.date}</h4>
                    <div className="theButtons">
                    <button id="men" className={menClass} onClick={this.changeFilter}>Male</button>
                    <button id="women" className={womenClass} onClick={this.changeFilter}>Female</button>
                    <button id="youth" className={youthClass} onClick={this.changeFilter}>Youth</button>
                    <button id="adults" className={adultClass} onClick={this.changeFilter}>Adults</button>
                    </div>
                    {selectTag}

                </div>
            </div>
        );
    }
}