import React from 'react';
import TopicField from './TopicField';

class Topic extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            editMode: false
        }
    }
    
    render() {
        const htmlAry = [];

        if( this.props.topics.proposed !== undefined ) {
            let topic = this.props.topics.proposed;

            htmlAry.push(
                <TopicField
                    key="proposed"
                    id="proposed"
                    value={topic}
                    label="Proposed Topic"
                    date={this.props.date}
                    status="proposed"
                    fetchWithQuery={this.props.fetchWithQuery}
                />
            );
        }
        let topic = this.props.topics.actual;

        htmlAry.push(
            <TopicField
                key="actual"
                id="actual"
                value={topic}
                label="Assigned Topic"
                date={this.props.date}
                status="actual"
                fetchWithQuery={this.props.fetchWithQuery}
            />
        );
        return (
            <div className="topic" onClick={this.changeToInput} >
                {htmlAry}
            </div>
        )    
    }
}
export default Topic