import React from 'react';
import MainContent from "./components/MainContent"
import './App.css';
import { relativeTimeThreshold } from 'moment';

let apiPrefix = "";
if( process.env.NODE_ENV !== "development" ) {
  apiPrefix = "https://theward.priceswrite.org";
}

class App extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      loading: true,
      speakerList: null,
      initialDate: -1,
      key: null,
      newkey: null
    }
    this.fetchWithQuery = this.fetchWithQuery.bind( this );
    this.queryParams = this.queryParams.bind( this );
  }

  async fetchWithQuery( url, options={}) {
    if(options.queryParams) {
        url += (url.indexOf('?') === -1 ? '?' : '&') + this.queryParams(options.queryParams);
        delete options.queryParams;
	}
	if(this.state.key ) {
		if(!options.headers) {
			options.headers = {}
		}
		options.headers['X-API-KEY'] = this.state.key;
	}
    return await fetch( apiPrefix + url, options );
  }

  queryParams( params ) {
      return Object.keys(params)
          .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&');
  }

  componentDidUpdate() {
    if( this.state.initialDate === 0 ) {
      let elem = document.getElementsByClassName( "TheNextSunday" );
      if( elem.length > 0 ) {
        elem[ 0 ].scrollIntoView();
        this.setState( { initialDate: 1 } );
      }
    }
  };

  async getSpeakerData() {
	const fetchPromiseArray = [];
	const headers = {
        'Content-Type': 'application/json'
	}
	if(this.state.key ) {
		headers['X-API-KEY'] = this.state.key;
	}
	const options = {
		headers: headers
	};
	fetchPromiseArray.push( fetch( apiPrefix + "/api/speakers", options ) );
    fetchPromiseArray.push( fetch( apiPrefix + "/api/topics", options ) );
    const fetchResultsArray = await Promise.all( fetchPromiseArray );

    const jsonPromiseArray = [];
    jsonPromiseArray.push( fetchResultsArray[ 0 ].json() );
    jsonPromiseArray.push( fetchResultsArray[ 1 ].json() );
    const jsonResultsArray = await Promise.all( jsonPromiseArray );

    const speakerResponse = jsonResultsArray[ 0 ];
    const topicResponse = jsonResultsArray[ 1 ];
    this.setState( prevState => {
      let newState = prevState;
      newState.speakerList = speakerResponse;
      newState.topicList = topicResponse;
      newState.loading = false;
      return newState;
    } )
  }

  async componentDidMount() {
    const key = localStorage.getItem( "talk_apikey" );
    try {
      this.setState( {
        initialDate: 0,
        key: key
      } )

      if( key ) {
        this.updateIdentity( key );
      }
    } catch( err ) {
        console.log( err );
    };
}

async postData( url = '', data = {}) {
  // Default options are marked with *
  let response;
  const headers = {
	'Content-Type': 'application/json'
  }
  if(this.state.key ) {
	  headers['X-API-KEY'] = this.state.key;
  }
  try {
    response = await fetch( apiPrefix + url, {
      method: 'POST',
      headers: headers,
      redirect: 'follow',
      body: JSON.stringify(data)
    });
  } catch( err ) {
    return {
      status: 404,
      statusText: "key not found"
    };    
  }
  let body;
  try {
    body = await response.json();
  } catch( err ) {
    return {
      status: response.status,
      statusText: response.statusText
    };
  }
  return {
    status: response.status,
    statusText: response.statusText,
    body: body
  }
}

async getJson( url ) {
	const headers = {
        'Content-Type': 'application/json'
	}
	if(this.state.key ) {
		headers['X-API-KEY'] = this.state.key;
	}
  try {
    const response = await fetch( apiPrefix + url, {
	  method: 'GET',
	  headers: headers,
      redirect: 'follow'
    } )
    if( response.status === 200 ) {
      return response.json();
    }
    return {
        status: response.status,
        error: response.statusText
    };
  }
  catch( err ) {
    return {
      error: err.message 
    };
  }
}

async updateIdentity( key ) {
  const response = await this.postData( "/api/role", { key: key } );
  if( response.status !== 200  ) {
    this.setState({ "key": null } );
    return;  
  }
  this.setState( {
    role: response.body.role,
    key: key
  } );
  if( response.body.allowClientStorage ) {
    localStorage.setItem( "talk_apikey", key );
  }
  this.getSpeakerData();
};

handleChange = (event) => {
  const input = event.target;
  const value = input.type === 'checkbox' ? input.checked : input.value;

  this.setState({ [input.name]: value });
};

handleNewKey = () => {
  if( this.state.newkey ) {
    this.setState( { key: this.state.newkey } );
  }
  this.updateIdentity( this.state.newkey );
};

  render() {
    let content;
    if( this.state.speakerList ) {
      content = <MainContent speakerList={this.state.speakerList} topicList={this.state.topicList} fetchWithQuery={this.fetchWithQuery} role={this.state.role}/>;
    } else if( !this.state.key ) {
      content = <div>
                  <div>
                  <img id="logo" src={ require('./logo310.png') } alt="talks" />
                  </div>
                  <label>
                    Key: <input id="keyfield" name="newkey" onChange={this.handleChange}/>
                  </label>
                  <button type="buttom" onClick={this.handleNewKey}>Start</button>
                </div>
                
    } else {
      content = <div><h3>loading . . .</h3></div>;
    }

    return (
      <div className="App">
        {content}
      </div>
    );  
  }
}

export default App;
