import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';

const isCharNumber = (c) => {
  return c >= '0' && c <= '9';
}

export default function ExemptList({fetchWithQuery, searchString}) {
  const [memberList, setMemberList] = useState([]);
  const [listInitialized, setlistInitialized] = useState(false);

  const requestExemptList = () => {
    return fetchWithQuery( "/api/exempt",
    {
      method: "GET",
      queryParams: {},
      headers: {
        "Content-Type": "application/json"
      }
    } )
    .then( ( response ) => {
        return response.json();
    } )
    .then( ( jsonResponse ) => {
        setMemberList(jsonResponse);
    })
    .catch( ( err ) => {
        console.log( err );
    } );
  };

  const updateMemberList = (id, months) => {
    const newMemberList = memberList.map((member) => {
      if(member.id === id) {
        member.months = months;
      }
      return member;
    })
    setMemberList(newMemberList);
  };

  const setMemberExempt = (id, months) => {
    updateMemberList(id, months);
    return fetchWithQuery( `/api/exempt/${id}`,
    {
      method: "PUT",
      queryParams: {
        months: months
      },
      headers: {
        "Content-Type": "application/json"
      }
    } )
    .then( ( response ) => {
      return;
  } )
  .catch( ( err ) => {
        console.log( err );
    } );
  };

  const handleChange = (event) => {
    let current = event.target.value;
    for( let idx = 0; idx < current.length; idx++) {
      const c = current.slice(idx, idx + 1);
      if(!isCharNumber(c)) {
        return;
      }
    }
    setMemberExempt(event.target.name, (current.length == 0) ? 0 : parseInt(current));
  };

  const displayList = () => {
    return memberList.filter((member) => {
      return member.name.toLowerCase().includes(searchString);
    });
  };

  if(!listInitialized) {
    setlistInitialized(true);
    requestExemptList();
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Months</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayList().map((member) => (
            <TableRow
              key={member.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {member.name}
              </TableCell>
              <TableCell>
                <TextField
                  value={member.months == 0 ? "" : member.months}
                  onChange={handleChange}
                  name={member.id}
                  variant="standard"
                  id={member.id}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
