import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

export default function Search({setSearchString}) {

  const searchStringChanged = (event) => {
    setSearchString(event.target.value.toLowerCase());
  };

  return (
    <>
        <TextField
          id="outlined-basic"
          placeholder='Filter'
          variant='outlined'
          onChange={searchStringChanged}
          size='small'
          sx={{input: {paddingTop: '4px', paddingBottom: '3px'}}}
        />
    </>
  );
}