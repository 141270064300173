import moment, { relativeTimeThreshold } from 'moment';
import React from 'react';
import Calendar from "./Calendar"
import ModeToggle from './ModeToggle';
import DatePicker from './DatePicker';
import ExemptList from './ExemptList';
import Search from './Search';

const SIX_DAYS = ( 1000 * 60 * 60 * 24 * 6 );

class MainContent extends React.Component {
	constructor( props ) {
		super( props );
		const now = moment();
        if( now.day() != 0 ) {
            now.day('Sunday')
            now.add(1, "weeks")
        }
		this.state = {
            mode: 'calendar',
            searchString: ''
        }
        this.scrollToTarget = this.scrollToTarget.bind(this);
        this.changeMode = this.changeMode.bind(this);
        this.wardDate = this.wardDate.bind(this);
        this.first = this.first.bind(this);
        this.last = this.last.bind(this);
        this.setSearchString = this.setSearchString.bind(this);
    }

    scrollToTarget(target) {
        const elem = document.getElementById( target.format( "YYYYMMDD" ) );
        elem.scrollIntoView();
    }

    wardDate( yyyymmdd, hh, mm, ss ) {
        if( hh === undefined ) {
            hh = 10;
            mm = 0;
            ss = 0;
        }
        const msOfDay = 1000 * ( ( hh * 60 * 60 ) + ( mm * 60 ) + ss );
        const givenTime = new Date( yyyymmdd );
        const offset = givenTime.getTimezoneOffset() * 60 * 1000;
        return new Date( givenTime.getTime() + offset + msOfDay) ;
    }

    first(now) {
        let first;

        if( this.props.speakerList.length > 0 ) {
            const firstTalk = this.wardDate( this.props.speakerList[ 0 ].date );
            first = new Date( firstTalk.getTime() - ( firstTalk.getDay() * ( 1000 * 60 * 60 * 24 ) ) );
        } else {
            first = new Date( now.getTime() - ( now.getDay() * ( 1000 * 60 * 60 * 24 ) ) );
        }
        return first
    }

    last(now) {
        let last = new Date( new Date().setFullYear( now.getFullYear() + 2) );
        last.setMonth(11);
        last.setDate(31);
        return last;
    }

    changeMode( mode ) {
        this.setState( prevState => {
            let newState = prevState;
            newState.mode = mode;
            return newState;
        });
    }

    setSearchString(searchString) {
        this.setState( prevState => {
            let newState = prevState;
            newState.searchString = searchString;
            return newState;
        });
    }

	render() {
        let toggleSwitch;
        switch(this.props.role) {
            case 'admin':
            case 'bishopric':
                toggleSwitch = <ModeToggle setDisplayMode={this.changeMode}/>
                break;
            default:
                toggleSwitch = ""

        }
        let body;
        let controls;
        switch(this.state.mode) {
            case 'exempt':
                controls = <Search setSearchString={this.setSearchString} />
                body = <ExemptList fetchWithQuery={this.props.fetchWithQuery} searchString={this.state.searchString} />
            break;
            default:
                const now = new Date();
                const first = this.first(now);
                const last = this.last(now);
                controls = <DatePicker scrollToTarget={this.scrollToTarget} firstSunday={moment(first)} lastSunday={moment(last)} />
                body = <Calendar speakerList={this.props.speakerList} topicList={this.props.topicList} fetchWithQuery={this.props.fetchWithQuery} first={first} last={last} now={now} />
        }

		return (
			<div class="box">
				<div class="fixed_header">
                    {controls}
                    <span className='toggleBlock'>{toggleSwitch}</span>
				</div>
				<div class="fixed_body">
                    {body}
				</div>
			</div>
		)
	}
}
export default MainContent