import moment, { now } from 'moment';
import React from 'react';

class DatePicker extends React.Component {
    // scrollToTarget, firstSunday, lastSunday
    constructor( props ) {
		super( props );
		this.state = {
			selectedDate: this.nextSunday(),
        }
		this.handleDayChange = this.handleDayChange.bind( this );
		this.handleMonthChange = this.handleMonthChange.bind( this );
		this.handleYearChange = this.handleYearChange.bind( this );
        this.nextSunday = this.nextSunday.bind( this );
        this.gotoThisSunday = this.gotoThisSunday.bind(this);
    }

    componentDidMount() {
        this.gotoThisSunday()
    };

	handleDayChange( e ) {
        const target = moment( this.state.selectedDate ).set( 'date', parseInt( e.target.value ) );
        this.updateDisplayDate(target);
    }

    handleMonthChange( e ) {
        const target = moment( this.state.selectedDate ).set( 'month', parseInt( e.target.value ) ).day( "Sunday" );
        this.updateDisplayDate(target);
    }

    handleYearChange( e ) {
        const target = moment( this.state.selectedDate ).set( 'year', parseInt( e.target.value ) ).day( "Sunday" );
        this.updateDisplayDate(target);
    }

    nextSunday() {
        let target = moment()
        if (0 != target.day()) {
            target.day('Sunday');
            target.add(1, "weeks")
        }
        return target
    }

    gotoThisSunday() {
        this.updateDisplayDate(this.nextSunday());
    }

    updateDisplayDate(target) {
        this.setState( prevState => {
            let newState = prevState;
            newState.selectedDate = target;
            return newState;
        });
        this.props.scrollToTarget(target)
    }

	render() {
        const yearpicker = [];
        for ( let year = parseInt( this.props.firstSunday.format("YYYY") ); year <= parseInt( this.props.lastSunday.format( "YYYY" ) ); year++ ) {
            yearpicker.push( <option key={year} value={year}>{year}</option> );
        }

        const theday = this.state.selectedDate.format( "D" );
        const themonth = this.state.selectedDate.format( "M" ) - 1;
        const theyear = this.state.selectedDate.format( "YYYY" );

		let dayPicker = [];
        let monthDay = parseInt( this.state.selectedDate.format( "D" ) );
        if( ( monthDay < 8 ) || dayPicker.length === 0 ) {
            dayPicker = [];

            while( monthDay > 7 ) {
                monthDay -= 7;
            }
            while( monthDay < 29 ) {
                dayPicker.push( <option key={monthDay} value={monthDay}>{monthDay}</option> );
                monthDay += 7;
            }
            if( monthDay < 32 ) {
                const datestr = `${ this.state.selectedDate.format( "YYYY-MM" ) }-${monthDay}`;
                if( moment( datestr ).isValid() ) {
                    dayPicker.push( <option key={monthDay} value={monthDay}>{monthDay}</option> );
                }
            }
        }

		return (
			<div>
                <span className="datePickerBlock">
                    <span className='datePicker' >
                        <select className='picker' value={themonth} onChange={this.handleMonthChange}>
                            <option value="0">Jan</option>
                            <option value="1">Feb</option>
                            <option value="2">Mar</option>
                            <option value="3">Apr</option>
                            <option value="4">May</option>
                            <option value="5">Jun</option>
                            <option value="6">Jul</option>
                            <option value="7">Aug</option>
                            <option value="8">Sep</option>
                            <option value="9">Oct</option>
                            <option value="10">Nov</option>
                            <option value="11">Dec</option>
                        </select>
                        <select className='picker' value={theday} onChange={this.handleDayChange}>
                            {dayPicker}
                        </select>,
                        <select className='picker' value={theyear} onChange={this.handleYearChange}>
                            {yearpicker}
                        </select>
                    </span>
                    <button className='goToThisSunday' onClick={this.gotoThisSunday}>Now</button>
                </span>
			</div>
		)
	}
}
export default DatePicker