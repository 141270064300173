import React from 'react';
import "./TopicField.css";
import PropTypes from 'prop-types';
// import { strict } from 'assert';
import moment from "moment";

class TopicField extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        locked: PropTypes.bool,
        focussed: PropTypes.bool,
        value: PropTypes.string,
        error: PropTypes.string,
        label: PropTypes.string,
        onChange: PropTypes.func,
     };
    static defaultProps = {
        locked: false,
        focussed: false,
        value: '',
        error: '',
        label: '',
        predicted: '',
        onChange: () => ''
    };
    
    constructor( props ) {
        super( props );
        this.state = {
            focussed: (props.locked && props.focussed) || false,
            value: props.value || '',
            error: props.error || '',
            label: props.label || ''
        }
    }
    
    onChange = event => {
        const { id } = this.props;
        const value = event.target.value;
        this.setState({ value, error: '' });
        return this.props.onChange(id, value);
    }

    onBlur = ( event ) => {
        if( !this.props.locked ) {
            const options = {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json"
                },
                queryParams: {
                    title: this.state.value,
                    date: moment(this.props.date).format( "YYYY-MM-DD" ),
                    status: this.props.status    
                }    
            }
            return this.props.fetchWithQuery( "/api/topic", options )
            .then( ( response ) => {
                return response.json();
            } )
            .then( ( jsonResponse ) => {
                this.setState({ focussed: false });
            } );
        }
    }

    render() {
        let content;
        const { focussedstate, value, error, label } = this.state;
        const { id, locked } = this.props;
        let focussed;
        if( locked && this.props.value ) {
            focussed = true;
        } else {
            focussed = focussedstate;
        }
        const fieldClassName = `field ${(locked ? focussed : focussed || value) && 'focussed'} ${locked && !focussed && 'locked'}`;

        if( this.props.locked ) {
            content = this.props.value;
        } else {
            content = value;
        }

        return (
            <div className={fieldClassName} >
                <input
                    id={id}
                    type="text"
                    value={content}
                    placeholder={label}
                    onChange={this.onChange}
                    onFocus={() => !locked && this.setState({ focussed: true })}
                    onBlur={this.onBlur}
                />
                <label htmlFor={id} className={error && 'error'}>
                    {error || label}
                </label>
            </div>
        );
    }
}
export default TopicField