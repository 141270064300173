import React from 'react';
import Talk from "./Talk";
import Topic from "./Topic";
import moment from "moment";

const SIX_DAYS = ( 1000 * 60 * 60 * 24 * 6 );

class Sunday extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
        }
      }

    render() {
        const todaysSpeakers = this.props.speakers.map( ( slot ) => {
            return <Talk
                key={slot.actual.key}
                slot={slot.actual.key}
                talks={slot}
                fetchWithQuery={this.props.fetchWithQuery}
                date={this.props.date}
                now={this.props.now} />
        } );

        const thedate = moment( this.props.date );
        let dateID;
        let dateClass;
        dateID = thedate.format( "YYYYMMDD" );
        if( this.props.date <= this.props.now ) {
            dateClass = "date datePast";
        } else {            
            if( ( ( this.props.date.getTime() - this.props.now.getTime() ) <  SIX_DAYS ) ) {
                dateClass = "date dateFuture TheNextSunday";
            } else {
                dateClass = "date dateFuture";
            }
        }
        const displayDate = thedate.format("MMM D, YYYY") 
        return (          
            <div className="sunday" >
                <div className="dateAndTopic">
                    <div className="dateBlock" id={dateID}>
                        <div className={dateClass}>
                            {displayDate}
                        </div>
                    </div>
                    <Topic
                        topics={this.props.topics}
                        date={this.props.date}
                        fetchWithQuery={this.props.fetchWithQuery}
                    />
                </div>
                <div className="speakers">{todaysSpeakers}</div>
            </div>
        )    
    }
}

export default Sunday