import React from 'react';
import TopicField from './TopicField';
import Candidates from './Candidates';
import moment from "moment";

class Speaker extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            candidates: {
                include: {
                    men: true,
                    women: true,
                    adults: props.slot !== 1 ? true: false,
                    youth: props.slot === 1 ? true: false,
                },
                show: false,
                showSelect: false,
                speaker: {
                    name: props.value || "",
                    status: props.status,
                    slot: props.slot,
                    date: moment( props.date ).format("YYYY-MM-DD")
                }
            },
            select: false,
            value: props.value,
            label: props.label
        };
        this.popupCandidates = this.popupCandidates.bind( this );
        this.closeCandidates = this.closeCandidates.bind( this );
        this.includeCandidates = this.includeCandidates.bind( this );
        this.selectCandidate = this.selectCandidate.bind( this );
        this.showSelect = this.showSelect.bind( this );
        this.hideSelect = this.hideSelect.bind( this );
    }

    selectCandidate( candidate ) {
      let newLabel;
      if( !candidate.value ) {
        newLabel = "";
      } else {
        newLabel = candidate.label
      }
        this.setState( prevState => {
            let newState = prevState;
            newState.candidates.show = false;
            newState.value = newLabel;
            return newState;
          } );
          return this.props.fetchWithQuery( "/api/speaker",
            {
              method: "PUT",
              queryParams: {
                name: candidate.label,
                uid: candidate.value,
                date: this.state.candidates.speaker.date,
                order: parseInt(this.state.candidates.speaker.slot),
                status: this.state.candidates.speaker.status
              },
              headers: {
                "Content-Type": "application/json"
              }
            } )
          .then( ( response ) => {
              return response.json();
          } )
          .then( ( jsonResponse ) => {
          })
          .catch( ( err ) => {
              console.log( err );
          } );
      
          // set state.candidates.show = false;
          // update state with new speaker          
    }

    hideSelect() {
        this.setState( ( currentState ) => {
            let newState = currentState;
            newState.candidates.showSelect = false;
            return newState;
          });      
    }

    includeCandidates( e ) {
        console.log( e );
        const btnName = e.currentTarget.id;
        let other = null;
        if( ( btnName === "men" ) && ( this.state.candidates.include.men === true )  && ( this.state.candidates.include.women === false ) ) {
          other = "women";
        } else if( ( btnName === "women" ) && ( this.state.candidates.include.women === true )  && ( this.state.candidates.include.men === false ) ) {
          other = "men";
        } else if( ( btnName === "youth" ) && ( this.state.candidates.include.youth === true )  && ( this.state.candidates.include.adults === false ) ) {
          other = "adults";
        } else if( ( btnName === "adults" ) && ( this.state.candidates.include.adults === true )  && ( this.state.candidates.include.youth === false ) ) {
          other = "youth";
        }
    
    
        this.setState( ( currentState ) => {
            let newState = currentState;
            newState.candidates.include[btnName] = !newState.candidates.include[btnName];
            if( other ) {
              newState.candidates.include[other] = !newState.candidates.include[other];
            }
            return newState;
        });
    }

    showSelect() {
        this.setState( ( currentState ) => {
            let newState = currentState;
            newState.candidates.showSelect = true;
            return newState;
        });
    }

    closeCandidates() {
        this.setState( ( currentState ) => {
            let newState = currentState;
            newState.candidates.show = false;
            return newState;
          });
    }

    popupCandidates( e ) {
        const candidates = this.state.candidates;
        candidates.show = true;
        candidates.showSelect = true;
        candidates.speaker.name = this.state.value;
    
        this.setState( ( currentState ) => {
          let newState = currentState;
          newState.candidates = candidates;
          return newState;
        });
    }   

    componentDidMount() {
        this.setState( prevState => {
            let newState = prevState;
            if( this.props.speaker.months ) {
              newState.value = `${this.props.speaker.name} ${this.props.speaker.months}`;  
            } else {
              newState.value = this.props.speaker.name;
            }
            return newState;
        } )
    }

    render() {
      return (
          <div className="speaker" onClick={this.popupCandidates}>
          <TopicField
              id={this.state.candidates.speaker.status}
              value={this.state.value}
              label={this.state.label}
              status={this.state.candidates.speaker.status}
              locked={true}
          />
          <Candidates showSelect={this.showSelect} hideSelect={this.hideSelect} fetchWithQuery={this.props.fetchWithQuery} includeCandidates={this.includeCandidates} selectCandidate={this.selectCandidate.bind(this)} close={this.closeCandidates} config={this.state.candidates} />
          </div>
      )
    }
}

export default Speaker