import React from 'react';
import Sunday from "./Sunday";
import moment from "moment";

class Calendar extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            loading: true
        }
    }

    fixScrollbar() {
        const bodyElm = document.getElementsByClassName("fixed_body");
        if(bodyElm.length > 0) {
            bodyElm[0].style.height = `${window.innerHeight-30}px`;
        }
    }

    componentDidMount() {
        this.fixScrollbar();
        window.onresize = () => this.fixScrollbar;
    };

    formatDate( date ) {
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        if( month < 10 ) {
            month = "0" + month;
        }
        let day = date.getDate();
        if( day < 10 ) {
            day = "0" + day;
        }

        return `${year}-${month}-${day}`;
    }

    getSundays( speakerArray ) {
        const sundays = [];
        const nowDateTime = this.props.now.getTime();
        let lastDateTime = this.props.last.getTime();

        let currentDate = this.props.first;

        let dayPicker = [];
        while( currentDate.getTime() < lastDateTime ) {
            let past = currentDate.getTime() < nowDateTime;
            const date = this.formatDate( currentDate );
            const topics = {};
            const speakers = [];

            if( !past ) {
                topics.proposed = "";
            }
            topics.actual = "";
            const todaysTopics = this.props.topicList.filter( ( topic ) => {
                return topic.date === date;
            } )
            todaysTopics.map( ( topic ) => {
                if( past && topic.status === "proposed" ) {
                    return false;
                }
                return topics[ topic.status ] = topic.title;
            } )

            const todaysSpeakers = speakerArray.filter( ( speakerObj ) => {
                return speakerObj.date === date;
            } );
            let count = 3;
            todaysSpeakers.forEach( ( speaker ) => {
                if( speaker.order > count ) {
                    count = speaker.order;
                }
            } )
            for( let idx = 1; idx <= count; idx++ ) {
                let pair = {};
                if( !past ) {
                    pair.proposed={};
                    pair.proposed.key = idx;
                }
                pair.actual={};
                pair.actual.key = idx;
                let orderPair = todaysSpeakers.filter( ( speakerObj ) => {
                    if( ( speakerObj.status === "proposed") && past ) {
                        return false;
                    }
                    return speakerObj.order === idx;
                } );
                orderPair.forEach( ( speaker ) => {
                    if( past && speaker.status === "proposal" ) {
                        return false;
                    }
                    speaker.key = pair[ speaker.status ].key;
                    pair[ speaker.status ] = speaker;
                } );
                speakers.push( pair );
            }

            sundays.push( <Sunday key={currentDate} now={this.props.now} date={currentDate} topics={topics} speakers={speakers} fetchWithQuery={this.props.fetchWithQuery} /> )
            currentDate = new Date( currentDate.getTime() + (1000 * 60 * 60 * 24 * 7) );
        }

        return sundays;
    }

    render() {
        let listOfSundays = this.getSundays( this.props.speakerList );

        return (
            <div className="calendar" >
                {listOfSundays}
             </div>
        )
    }
}

export default Calendar