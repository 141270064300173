import React from 'react';
import Speaker from "./Speaker"

class Talk extends React.Component {
    render() {
        let speakers = [];
        let slotName;
        switch( this.props.slot ) {
            case 1:
                slotName = "1st";
                break;
            case 2:
                slotName = "2nd";
                break;
            case 3:
                slotName = "3rd";
                break;
            default:
                slotName = "";
        }    

        if( this.props.talks.proposed ) {
            const label = slotName + " Proposed Speaker";
            speakers.push(
                <Speaker key="proposed"
                status="proposed"
                speaker={this.props.talks.proposed }
                slot={this.props.slot}
                now={this.props.now}
                label={label}
                date={this.props.date}
                fetchWithQuery={this.props.fetchWithQuery} />);
        }
        const label = slotName + " Speaker";
        speakers.push(            
            <Speaker key="actual"
             status="actual"
             speaker={this.props.talks.actual}
             slot={this.props.slot}
             now={this.props.now}
             label={label}
             date={this.props.date}
             fetchWithQuery={this.props.fetchWithQuery} />);
    
        return (
            <div className="talk">
                {speakers}
            </div>
        )
    }
}

export default Talk